import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title }) => {
  return (
    <div>
      <Helmet title={title}>
        {/* <title>ThermoHub NET Gatsby React Application by CONGINEER Ltd.</title> */}
        <meta
          name='description'
          content='ThermoHub NET Gatsby React Application by CONGINEER Ltd.'
        />
        <meta
          name='og:title'
          property='og:title'
          content='ThermoHub NET Gatsby React Application by CONGINEER Ltd.'
        ></meta>
        <meta
          name='twitter:card'
          content='ThermoHub NET Gatsby React Application by CONGINEER Ltd.'
        ></meta>
        <link rel='canonical' href='https://congineer.com'></link>
        {/* <meta
          property='og:image'
          content='https://congineer.com/thermohub.net.png'
        /> */}
      </Helmet>
    </div>
  )
}

export default SEO
