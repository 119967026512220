import React from 'react'
import { RecoilRoot } from 'recoil'
import SEO from './SEO' /* eslint-disable */
import GoTop from './GoTop'

const Layout = ({ children }) => {
  return (
    <RecoilRoot>
      <SEO />
      {children}
      <GoTop scrollStepInPx='100' delayInMs='10.50' />
    </RecoilRoot>
  )
}

export default Layout
